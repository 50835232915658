import SettingsServices from "../../services/SettingsServices"
export const namespaced = true

export const state = {
    settings: [],
    addresses:[],
    organizations:[],
    customer_types:[],
    discounts:[]
}

export const mutations = {
  SET_SETTINGS_DATA(state, rdata) {
        state.settings = rdata
        localStorage.setItem('settings', JSON.stringify(rdata))
      },
  SET_ADDRESSES_DATA(state, rdata) {
        state.addresses = rdata
        localStorage.setItem('addresses', JSON.stringify(rdata))
      },
  SET_ORGANIZATIONS_DATA(state, rdata) {
        state.organizations = rdata
        localStorage.setItem('organizations', JSON.stringify(rdata))
      },
  SET_DISCOUNTS_DATA(state, rdata) {
        state.discounts = rdata
        localStorage.setItem('discounts', JSON.stringify(rdata))
      },
  SET_CUSTOMER_TYPES_DATA(state, rdata) {
        state.customer_types = rdata
        localStorage.setItem('customer_types', JSON.stringify(rdata))
      },
}

export const actions = {
    getAllSettings ({commit}){
        return SettingsServices.getSettings()
        .then(response => {
            commit('SET_SETTINGS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting settings', error)
          })
    },
    getAllAddresses ({commit}){
      return SettingsServices.getAddresses()
      .then(response => {
          commit('SET_ADDRESSES_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting settings', error)
        })
  },
  getAllOrganizations ({commit}){
    return SettingsServices.getOrganizations()
    .then(response => {
        commit('SET_ORGANIZATIONS_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting organizations', error)
      })
},
getAllDiscounts ({commit}){
  return SettingsServices.getDiscounts()
  .then(response => {
      commit('SET_DISCOUNTS_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting discounts', error)
    })
},
getAllCustomerTypes ({commit}){
  return SettingsServices.getCustomerTypes()
  .then(response => {
      commit('SET_CUSTOMER_TYPES_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting customer types', error)
    })
}
}

export const getters = {
}

import apiClient from './ApiServices'

export default{
    name: 'RolesServices',

    getRoles() {
        return apiClient.get('accounts/role/')
    },
    
    createRole(role) {
        return apiClient.post('accounts/role/',role)
    },
    updateRole(id,perm) {
        return apiClient.put('accounts/role/'+id+'/',perm)
    },
    deleteRole(id) {
        return apiClient.delete('accounts/role/'+id)
    },
    getModules(){
        return apiClient.get('accounts/module/')
    },
    getScopes(){
        return apiClient.get('accounts/scope/')
    },
    addToCustomer(id,role){
        return apiClient.put('accounts/customer/'+id+'/',role)
    }
}
import apiClient from "./ApiServices";

export default {
  name: "AssessmentService",

  getAssessments() {
    return apiClient.get("assessment/");
  },
  getAssessmentsPage(pageid, size, stat) {
    return apiClient.get(
      "assessment/?page=" + pageid + "&page_size=" + size + "&status=" + stat
    );
  },
  getAssessmentsFilter(pageid, size, stat, filterString) {
    return apiClient.get(
      "assessment/?page=" +
        pageid +
        "&page_size=" +
        size +
        "&status=" +
        stat +
        filterString
    );
  },
  getTodaysAssessments(id) {
    return apiClient.get("assessment/today/?assessor=" + id);
  },
  getNCAssessments(id) {
    return apiClient.get("assessment/missing-comments/?assessor=" + id);
  },
  getRAAssessments(id) {
    return apiClient.get("assessment/missing-reports/?assessor=" + id);
  },
  getAssessment(id) {
    return apiClient.get("assessment/" + id + "/");
  },
  getAssessmentCustomer(id) {
    return apiClient.get("/accounts/customer/" + id + "/assessments/");
  },
  getAssessmentLogs(id) {
    return apiClient.get("logs/assessment/?instance=" + id);
  },
  cancelAssessment(id, reason) {
    return apiClient.post("assessment/" + id + "/cancel-assessment/", reason);
  },
  deleteAssessment(id) {
    return apiClient.delete("assessment/" + id + "/");
  },
  getAllReports(pageid, size, startDate, endDate) {
    if (startDate && endDate) {
      return apiClient.get(
        "assessment/safety-net/?page=" +
          pageid +
          "&page_size=" +
          size +
          "&start_date=" +
          startDate +
          "&end_date=" +
          endDate
      );
    } else {
      return apiClient.get(
        "assessment/safety-net/?page=" + pageid + "&page_size=" + size
      );
    }
  },
  getCustomersList(pageid, size) {
    return apiClient.get(
      "assessment/gcaa-report/?page=" + pageid + "&page_size=" + size
    );
  },
  getFinanceReport(pageid, size, startDate, endDate) {
    if (startDate && endDate) {
      return apiClient.get(
        "assessment/finance-report/?page=" +
          pageid +
          "&page_size=" +
          size +
          "&start_date=" +
          startDate +
          "&end_date=" +
          endDate
      );
    } else {
      return apiClient.get(
        "assessment/finance-report/?page=" + pageid + "&page_size=" + size
      );
    }
  },

  addReport(id, report) {
    return apiClient.post("assessment/" + id + "/add-report/", report);
  },
  updateReport(id, report) {
    return apiClient.put("report/" + id + "/update-task-levels/", report);
  },
  addReportComment(id, comment) {
    return apiClient.put("report/" + id + "/", comment);
  },
  addReportAll(id, report) {
    return apiClient.post("assessment/" + id + "/add-report-tasks/", report);
  },
  addReportTHR(id, report) {
    return apiClient.post("dispute/" + id + "/add-report/", report);
  },
  getReportELE(id) {
    return apiClient.get("/assessment/" + id + "/ele-report/");
  },
  changeSlot(slotid, assessid) {
    return apiClient.post("/assessment/" + assessid + "/change-slot/", slotid);
  },
  getReportOPE(id) {
    return apiClient.get("/assessment/" + id + "/ope-report/");
  },
  getTasks() {
    return apiClient.get("task/");
  },
  addComment(id, comment) {
    return apiClient.put("report/" + id + "/", comment);
  },
  addAdminComment(id, comment) {
    return apiClient.post("assessment/" + id + "/admin-comment/", comment);
  },
  putAdminComment(id, comment) {
    return apiClient.put("assessment/" + id + "/admin-comment/", comment);
  },
  onGoing(id) {
    return apiClient.post("/assessment/" + id + "/ongoing-assessment/");
  },
  pending(id) {
    return apiClient.post("/assessment/" + id + "/pending-assessment/");
  },
  getAllDisputes() {
    return apiClient.get("/dispute/");
  },
  getDispute(id) {
    return apiClient.get("/dispute/" + id + "/");
  },
  getAssessmentDispute(id) {
    return apiClient.get("/assessment/" + id + "/disputes/");
  },
  raiseDispute(id, dispute) {
    return apiClient.post("/assessment/" + id + "/raise-dispute/", dispute);
  },
  updateDispute(id, update) {
    return apiClient.put("/dispute/" + id + "/", update);
  },
  closeDispute(id, summary) {
    return apiClient.post("/dispute/" + id + "/close-dispute/", summary);
  },
  validateDispute(id, obj) {
    return apiClient.post("/dispute/" + id + "/validate-dispute/", obj);
  },
  ApproveDispute(id, obj) {
    return apiClient.post("/dispute/" + id + "/dispute-approval/", obj);
  },
};

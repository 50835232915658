<template>
<div  class="font-sans bg-t3-body bg-opacity-50 min-h-screen flex flex-col flex-auto flex-shrink-0" :class="{'h-16 overflow-y-hidden md:overflow-y-auto': !enableScroll}">
<router-view />
      </div>

</template>

<style>
</style>

<script>

// import { watchEffect } from 'vue'

  export default {
    name: "loutLayout",
    components: {

    },
    data() {
      return {
        isClicked: true,
        enableScroll: true,
      }
    },

    updated() {
    },
    computed:{
        
    },
    created() {       
    },
    methods: {
          
    },
  }
</script>
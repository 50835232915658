import AServices from '@/services/AuthService.js'
import UServices from '@/services/UsersServices.js'

export const namespaced = true

export const state = {
  access_token: null,
  expiry: null,
  scopes: null,
  user: null,
  userDetail:null,
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.access_token = userData.access_token
    var today = new Date()
    state.expiry = today.getTime()+(userData.expires_in * 1000)
    state.scopes = userData.scope.split(' ')
    state.user = userData
    localStorage.setItem('auth', JSON.stringify(userData))
    localStorage.setItem('expiry', JSON.stringify(state.expiry))
  },
  SET_USERDETAILS(state,detailUser){
    
    state.userDetail = detailUser
    localStorage.setItem('scopes', JSON.stringify(detailUser.scopes_name))
    localStorage.setItem('userdetail', JSON.stringify(detailUser))
  },
  CLEAR_USER_DATA(state) {
    localStorage.removeItem('userdetail')
    localStorage.removeItem('scopes')
    localStorage.removeItem('auth')
    state.access_token = null
    state.scopes = null
    state.user = null
    location.reload()
  },
  SET_DATA(state, user) {
    state.user = user
    state.access_token = user.access_token
  }
}

export const actions = {
  getAccessToken({ commit }, params) {
    return AServices.getAccessToken(params)
      .then(response => {
        commit('SET_USER_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting access token', error)
      })
  },
  getUserDetails({commit}){
    UServices.getCustomerDetails()
    .then(response => {

        commit('SET_USERDETAILS', response.data)
    })
    .catch(error => {
      if(error.response.status===401){
        commit('CLEAR_USER_DATA')
      }
      console.log('Error getting details token', error.response.status)
    })
  },
  logout({ commit }) {
    commit('CLEAR_USER_DATA')
  },
  fetchUserData({ commit }) {
    const user = localStorage.getItem('auth')
    if (user) {
      commit('SET_DATA', user)
    }
  }
}

export const getters = {
  loggedIn(state) {
      return !!state.user
  },
  isAdmin(state) {
    if (state.scopes) {
      return state.scopes.includes('elp_admin')
    }
    return false
  },
  isManagement(state) {
    if (state.scopes) {
      return state.scopes.includes('elp_management')
    }
    return false
  }
}

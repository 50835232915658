
import axios from 'axios'

const apiClient = axios.create({

    baseURL: process.env.VUE_APP_ELP_SEC+'://' + process.env.VUE_APP_ELP_URL + '/',  
    withCredentials: false,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    
})

apiClient.interceptors.request.use(

    config => {
    const user = JSON.parse(localStorage.getItem('auth'))
    if (user) {
    config.headers.Authorization = `Bearer ${user.access_token}`
    }
    return config
    },
    error => Promise.reject(error)
)

export default apiClient
import apiClient from './ApiServices'
import apiClient2 from './GuestServices'

export default{
    name: 'SettingsServices',

    getSettings() {
        return apiClient.get('accounts/company/settings/')
    },
    updateSettings(id,settings) {
        return apiClient.put('accounts/settings/'+id+'/',settings)
    },
    getAddresses(){
        return apiClient.get('/accounts/address/')
    },
    createAddress(address){
        return apiClient.post('/accounts/address/',address)
    },
    updateAddress(id,address){
        return apiClient.put('/accounts/address/'+id+'/',address)
    },
    deleteAddress(id){
        return apiClient.delete('/accounts/address/'+id+'/')
    },
    getOrganizations(){
        return apiClient2.get('accounts/organization/')
    }
    ,
    createOrganization(org){
        return apiClient.post('/accounts/organization/',org)
    },
    updateOrganization(id,organization){
        return apiClient.put('/accounts/organization/'+id+'/',organization)
    },
    deleteOrganization(id){
        return apiClient.delete('/accounts/organization/'+id+'/')
    },
    createDiscount(disc){
        return apiClient.post('/accounts/discount-rate/',disc)
    },
    updateDiscount(id,disc){
        return apiClient.put('/accounts/discount-rate/'+id+'/',disc)
    },
    deleteDiscount(id){
        return apiClient.delete('/accounts/discount-rate/'+id+'/')
    },
    getDiscounts(){
        return apiClient.get('accounts/discount-rate/')
    },
    getDiscountsUser(id){
        return apiClient.get('accounts/customer/'+id+'/discount-rates/')
    },
    createCustomerType(type){
        return apiClient.post('/accounts/customer-type/',type)
    },
    updateCustomerType(id,type){
        return apiClient.put('/accounts/customer-type/'+id+'/',type)
    },
    deleteCustomerType(id){
        return apiClient.delete('/accounts/customer-type/'+id+'/')
    },
    getCustomerTypes(){
        return apiClient.get('accounts/customer-type/')
    }
}
import RecordingServices from "../../services/RecordingServices"
export const namespaced = true

export const state = {
    recordings: [],
    assessmentRecordings:[]
}

export const mutations = {
  SET_RECORDING_DATA(state, recordingData) {
        state.recordings = recordingData
        localStorage.setItem('recordings', JSON.stringify(recordingData))
      },

      SET_ASSESSMENT_RECORDING_DATA(state, recordingData) {
        state.assessmentRecordings = recordingData
        localStorage.setItem('assessmentRecordings', JSON.stringify(recordingData))
      }
}

export const actions = {

    getRecordings ({commit}){
      return RecordingServices.getRecordings()
      .then(response => {
          commit('SET_RECORDING_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting recordings data', error)
        })
    },

    getAssessmentRecordings({commit}, id) {
      return RecordingServices.getAssessmentRecordings(id)
        .then(response => {
            commit('SET_ASSESSMENT_RECORDING_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting the required recordings for this assessment', error)
          })
    }
  }

export const getters = {
  
}

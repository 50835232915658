<template>
<div v-if="!loggedIn" class="bg-indigo-900 relative overflow-hidden h-screen animate-pulse">
    <img src="../assets/land-bg.jpg" class="absolute h-full w-full object-cover"/>
    <div class="inset-0 bg-t3 opacity-80 absolute">
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
import randomstring from "randomstring";
import Swal from 'sweetalert2';
import { mapState } from 'vuex';

export default {
  name: "LandingPage",
  data: () => ({
      url: "",
      authLog: process.env.VUE_APP_ELP_SEC +'://'+process.env.VUE_APP_AUTH_URL+'/accounts/api/logout-user/?redirect_url='+process.env.VUE_APP_ELP_SEC +'://'+process.env.VUE_APP_REDIR_URL
  }),
  created() {
    this.buildUrl()
  },
  methods: {
    buildUrl() {
      const baseUrl = new URL(process.env.VUE_APP_ELP_SEC+"://" + process.env.VUE_APP_AUTH_URL + "/o/authorize/")
      const redirect_uri = process.env.VUE_APP_ELP_SEC+"://" +process.env.VUE_APP_REDIR_URL + "/"

      const queryParams = {
        client_id: process.env.VUE_APP_CLIENT_ID,
        response_type: "code",
        state: this.state,
        redirect_uri
      };

      for (const param in queryParams) {
        baseUrl.searchParams.append(param, queryParams[param])
      }
      this.saveState()
      this.url = baseUrl
      const currentLocation = new URL(window.location.href)
      const auth_code = currentLocation.searchParams.get("code")
      if(!auth_code && !localStorage.getItem('userdetail')){
        const error = currentLocation.searchParams.get("error")
        if(error !== "access_denied"){

          window.location.href = baseUrl.toString();
          
        }
        else{
          Swal.fire( {
                    title:"Access Denied", 
                    text:"Log out and try again!",
                    icon:"error",
				showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
			}).then((result) => {
				if (result.value) {
          window.location.href = this.authLog.toString();
				}
			})
          
        }
      }
    },
    saveState() {
      if (window.location.search.includes("state")) return;
      const storage = window.sessionStorage;
      storage.clear();
      storage.setItem("state", this.state);
    },
  },
  computed: {
    state: function() {
      return randomstring.generate()
    },
    ...mapState({
      user: state => state.Auth.user,
    }),
  }
}
</script>
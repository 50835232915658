import DocumentServices from "../../services/DocumentServices"
export const namespaced = true

export const state = {
    documents: [],
    document:{}
}

export const mutations = {
  SET_DOCUMENTS_DATA(state, documentData) {
        state.documents = documentData
        localStorage.setItem('documents', JSON.stringify(documentData))
      },
      SET_DOCUMENT_DATA(state, documentData) {
        state.document = documentData
        localStorage.setItem('document', JSON.stringify(documentData))
      },
  CLEAR_DOC_DATA(state) {
        state.documents = []
        localStorage.setItem('documents', JSON.stringify(null))
      }
}

export const actions = {
    getDocuments ({commit},id){
      return DocumentServices.getDocuments(id)
      .then(response => {
          commit('SET_DOCUMENTS_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting documents data', error)
        })
  },
  getDocument ({commit},id){
    return DocumentServices.getDocument(id)
    .then(response => {
        commit('SET_DOCUMENT_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting document data', error)
      })
},
  clearDocs({commit}){
    commit('CLEAR_DOC_DATA')
  }
}
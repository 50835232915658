import apiClient from './ApiServices'

export default{
    name: 'documentService',

    getDocuments(id) {
        return apiClient.get('/accounts/customer/'+id+'/documents/')
    },
    sendDocument(document) {
        return apiClient.post("/accounts/document/",document)
    },
    getDocument(id){
        return apiClient.get("/accounts/document/"+id,"/")
    },
    deleteDocument(id){
        return apiClient.delete("/accounts/document/"+id,"/")
    }
    
}
import StatServices from "../../services/StatServices"
export const namespaced = true

export const state = {
    dashboard: {},
    finances:{}
}

export const mutations = {
  SET_DASH_DATA(state, sData) {
        state.dashboard = sData
        localStorage.setItem('dashboardStats', JSON.stringify(sData))
      },
      SET_FINS_DATA(state, sData) {
        state.finances = sData
        localStorage.setItem('financeStats', JSON.stringify(sData))
      }
}

export const actions = {
    getDashboard({commit}, obj){
      return StatServices.getAssessmentStatistics(obj)
      .then(response => {
          commit('SET_DASH_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting dashboard statistics data', error)
        })
  },
  getFinances({commit}, obj){
  return StatServices.getFinancialStatistics(obj)
  .then(response => {
      commit('SET_FINS_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting finance statistics data', error)
    })
}
}

export const getters = {
  
}

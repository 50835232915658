import apiClient from './ApiServices'

export default{
    name: 'QuestionsServices',

    getQuestions () {
        return apiClient.get('question/')
    },
    getQuestionsPage(pageid,size,task) {
        return apiClient.get('question/?page='+pageid+"&page_size="+size+"&for_task="+task)
    },
    getQuestionsFilter(pageid,size,task,type) {
        return apiClient.get('question/?page='+pageid+"&page_size="+size+"&for_task="+task+"&license_type="+type)
    },
    getQuestionsDeleted (pageid,size,task) {
        return apiClient.get('question/deleted/?page='+pageid+"&page_size="+size+"&for_task="+task)
    },
    getQuestionsFiltered (cus) {
        return apiClient.get('question/?type='+ cus)
    },
    updateQuestion(id, licenses){
        return apiClient.put('question/'+id+'/', licenses)
    },
    postQuestion (question) {
        return apiClient.post('question/', question)
    },
    deleteQuestion (question) {
        return apiClient.delete('question/'+ question+'/')
    },
    restoreQuestion (question) {
        return apiClient.post('question/'+ question+'/recover/')
    },
}
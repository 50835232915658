import Vuex from 'vuex'
import * as Auth from '@/store/modules/Auth.js'
import * as Assessments from '@/store/modules/Assessments.js'
import * as Users from '@/store/modules/Users.js'
import * as Questions from '@/store/modules/Questions.js'
import * as Slots from '@/store/modules/Slots.js'
import * as Requests from '@/store/modules/Requests.js'
import * as Samples from '@/store/modules/Samples.js'
import * as Recordings from '@/store/modules/Recordings.js'
import * as Documents from '@/store/modules/Documents.js'
import * as Roles from '@/store/modules/Roles.js'
import * as Settings from '@/store/modules/Settings.js'
import * as Statistics from  '@/store/modules/Statistics.js'
import * as Filters from  '@/store/modules/Filters.js'

export default new Vuex.Store({
  name: 'vuex',
  state: {
    
  },
  modules: {
    Auth,
    Assessments,
    Users,
    Questions,
    Slots,
    Requests,
    Samples,
    Recordings,
    Documents,
    Roles,
    Settings,
    Statistics, 
    Filters
  }
})

import SlotsServices from "../../services/SlotsServices"
export const namespaced = true

export const state = {
    slots: [],
    slotD:[],
    booked: []
}

export const mutations = {
  SET_SLOTS_DATA(state, slotsData) {
        state.slots = slotsData
        localStorage.setItem('slots', JSON.stringify(slotsData))
      },
  SET_SLOTS_DATED_DATA(state, slotsData) {
        state.slotD = slotsData
        localStorage.setItem('slotD', JSON.stringify(slotsData))
      },
  CLEAR_SLOT_DATA(state) {
        state.slots = null
        localStorage.setItem('slots', JSON.stringify(null))
      },
  SET_BOOKED_DATA(state, slotsData) {
        state.booked = slotsData
        localStorage.setItem('slots', JSON.stringify(slotsData))
      }
}

export const actions = {
    getAllSlots ({commit}){
        return SlotsServices.getSlots()
        .then(response => {
            commit('SET_SLOTS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting slots data', error)
          })
    },
    getSlotsForDate ({commit},date){
      return SlotsServices.getSlotsForDate(date)
      .then(response => {
          commit('SET_SLOTS_DATED_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting slots data', error)
        })
  },
    getBookedSlots ({commit}){
      return SlotsServices.getBookedSlots()
      .then(response => {
          commit('SET_BOOKED_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting slots data', error)
        })
  },
  clearSlots({commit}){
    commit('CLEAR_SLOT_DATA')
  }
}

export const getters = {
  
}

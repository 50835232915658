import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import * as Sentry from "@sentry/vue";
import router from './router';
import store from './store';
import './assets/tailwind.css'; 
import { BrowserTracing } from "@sentry/tracing"
import 'tw-elements';
import ReportServices from './services/ReportServices';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
const app = createApp(App)

Sentry.init({app,
    dsn: "https://30f6dbd2a07b4e2e9fb8280e6100e003@o4504711319715840.ingest.sentry.io/4504711356481537",

    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,


    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "api.t3-elp.com",'wilco-aviation.com', 't3a.app', /^\//],
        }),
        new Sentry.Replay()
    ],
    tracesSampleRate: 0.1,

    });


app.use(router).
use(store).
component(ReportServices,"ReportServices").
component('EasyDataTable', Vue3EasyDataTable).
mount('#app')

import BookingServices from "../../services/BookingServices"
export const namespaced = true

export const state = {
    bookings: []
}

export const mutations = {
  SET_BOOKED_DATA(state, bData) {
        state.bookings = bData
        localStorage.setItem('slots', JSON.stringify(bData))
      }
}

export const actions = {
    getBookings ({commit}){
      return BookingServices.getBookings()
      .then(response => {
          commit('SET_BOOKED_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting bookings data', error)
        })
  }
}

export const getters = {
  
}

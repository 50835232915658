import SamplesServices from "../../services/SamplesServices"
export const namespaced = true

export const state = {
    samples: []
}

export const mutations = {
  SET_SAMPLES_DATA(state, samplesData) {
        state.samples = samplesData
        localStorage.setItem('samples', JSON.stringify(samplesData))
      },
}

export const actions = {
    getAllSamples ({commit}){
        return SamplesServices.getSamples()
        .then(response => {
            commit('SET_SAMPLES_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting questions', error)
          })
    },
    getSample ({commit}, id){
      return SamplesServices.getSample(id)
      .then(response => {
          commit('SET_SAMPLES_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting questions', error)
        })

  },
  deleteSample ({commit}){
    return commit('SET_SAMPLES_DATA', null)
}
}
export const getters = {
  
}

import AssessmentsServices from "../../services/AssessmentsServices"
export const namespaced = true

export const state = {
    assessments: [],
    assessment:{},
    assessmentCustomer:[],
    todays:[],
    noComment:[],
    reqAction:[],
    next:null,
    count:null,
    customersList:null,
    finance:null,
    nextReport:null,
    countReport:null,
    nextC:null,
    countC:null,
    nextF:null,
    countF:null,
    logs:[],
    reports:[],
    elereport:[],
    opereport:[],
    tasks: [],
    disputes:[],
    dispute:null,
    cert_assessment:null
}

export const mutations = {
    SET_ASSESSMENTS_DATA(state, assessmentsData) {
      state.assessments = assessmentsData.results
      state.next = assessmentsData.next
      state.count = assessmentsData.count
      localStorage.setItem('assessments', JSON.stringify(assessmentsData))
    },
    SET_TODAYS_DATA(state, assessmentsData) {
      state.todays = assessmentsData
      localStorage.setItem('todays', JSON.stringify(assessmentsData))
    },
    SET_REQS_DATA(state, assessmentsData) {
      state.reqAction = assessmentsData
      localStorage.setItem('RAAssessments', JSON.stringify(assessmentsData))
    },
    SET_COMM_DATA(state, assessmentsData) {
      state.noComment = assessmentsData
      localStorage.setItem('NCAssessments', JSON.stringify(assessmentsData))
    },
    SET_ASSESSMENT_DATA(state, assessmentData) {
      state.assessment = assessmentData
      localStorage.setItem('assessment', JSON.stringify(assessmentData))
    },
    SET_ASSESSMENT_CUSTOMER_DATA(state, assessmentData) {
      state.assessmentCustomer = assessmentData
      localStorage.setItem('assessmentsCustomer', JSON.stringify(assessmentData))
    },
    SET_CERT_ASSESSMENT_DATA(state, assessmentData) {
      state.cert_assessment = assessmentData
    },
    SET_ASSESSMENT_LOG_DATA(state, logData) {
      state.logs = logData
      localStorage.setItem('assessmentLog', JSON.stringify(logData))
    },
    SET_REPORTS_DATA(state, reportsData) {
      state.reports = reportsData.results
      state.nextReport = reportsData.next
      state.countReport = reportsData.count
      localStorage.setItem('reports', JSON.stringify(reportsData))
    },
    SET_CLIST_DATA(state, reportsData) {
      state.customersList = reportsData.results
      state.nextC = reportsData.next
      state.countC = reportsData.count
      localStorage.setItem('customer_reports', JSON.stringify(reportsData))
    },
    SET_FINANCE_DATA(state, reportsData) {
      state.finance = reportsData.results
      state.nextF = reportsData.next
      state.countF = reportsData.count
      localStorage.setItem('finance_reports', JSON.stringify(reportsData))
    },
    SET_ELEREPORT_DATA(state, reportsData) {
      state.elereport = reportsData
      localStorage.setItem('elereport', JSON.stringify(reportsData))
    },
    SET_OPEREPORT_DATA(state, reportsData) {
      state.opereport = reportsData
      localStorage.setItem('opereport', JSON.stringify(reportsData))
    },
    SET_TASKS_DATA(state, tasksData) {
      state.tasks = tasksData
      localStorage.setItem('tasks', JSON.stringify(tasksData))
    },
    SET_DISPUTES_DATA(state, tasksData) {
      state.disputes = tasksData
      localStorage.setItem('disputes', JSON.stringify(tasksData))
    },
    SET_DISPUTE_DATA(state, disputeData) {
      state.dispute = disputeData
    },
    SET_ASSESSMENT_DISPUTES_DATA(state, tasksData) {
      state.disputes = tasksData
      localStorage.setItem('assessment_disputes', JSON.stringify(tasksData))
    },
}

export const actions = {
    getAllAssessments ({commit}){
        return AssessmentsServices.getAssessments()
        .then(response => {
            commit('SET_ASSESSMENTS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting assessments data', error)
          })
    },
    getAssessmentsPage ({commit},obj){
          return AssessmentsServices.getAssessmentsFilter(obj.pageid,obj.number,obj.stat,obj.filterString)
          .then(response => {
              commit('SET_ASSESSMENTS_DATA', response.data)
            })
            .catch(error => {
              console.log('Error getting assessments data', error)
            })
          },
    getTodaysAssessment({commit},id){
      return AssessmentsServices.getTodaysAssessments(id)
          .then(response => {
              commit('SET_TODAYS_DATA', response.data)
              AssessmentsServices.getNCAssessments(id)
          .then(respons => {
              commit('SET_COMM_DATA', respons.data)
              
            })
            .catch(error => {
              console.log('Error getting assessments data', error)
            })
            AssessmentsServices.getRAAssessments(id)
          .then(respons => {
              commit('SET_REQS_DATA', respons.data)
              
            })
            .catch(error => {
              console.log('Error getting assessments data', error)
            })
            })
            .catch(error => {
              console.log('Error getting assessments data', error)
            })
    },
    getCertAssessment({commit},id){
      return AssessmentsServices.getAssessmentCustomer(id)
      .then(response => {
        if(response.length > 0)
          commit('SET_CERT_ASSESSMENT_DATA', response.data[0])
          
        })
        .catch(error => {
          console.log('Error getting assessments data', error)
        })
      },
    getAssessment ({commit},id){
      return AssessmentsServices.getAssessment(id)
      .then(response => {
          commit('SET_ASSESSMENT_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting assessments data', error)
        })
  },
  
  getAssessmentCustomer({commit},id){
    return AssessmentsServices.getAssessmentCustomer(id)
    .then(response => {
        commit('SET_ASSESSMENT_CUSTOMER_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting assessments data', error)
      })
},
  getAssessmentLogs ({commit},id){
    return AssessmentsServices.getAssessmentLogs(id)
    .then(response => {
        commit('SET_ASSESSMENT_LOG_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting assessment logs  data', error)
      })
},
getReports ({commit},obj){
  return AssessmentsServices.getAllReports(obj.id,obj.number,obj.startDate,obj.endDate)
  .then(response => {
      commit('SET_REPORTS_DATA', response.data)
      
    })
    .catch(error => {
      console.log('Error getting reports data', error)
    })
},
getCustomersList ({commit},obj){
  return AssessmentsServices.getCustomersList(obj.id,obj.number)
  .then(response => {
      commit('SET_CLIST_DATA', response.data)
      
    })
    .catch(error => {
      console.log('Error getting reports data', error)
    })
},
getFinanceReport({commit},obj){
  return AssessmentsServices.getFinanceReport(obj.id,obj.number,obj.startDate,obj.endDate)
  .then(response => {
      commit('SET_FINANCE_DATA', response.data)
      
    })
    .catch(error => {
      console.log('Error getting reports data', error)
    })
},
  getELEReport ({commit},id){
    return AssessmentsServices.getReportELE(id)
    .then(response => {
        commit('SET_ELEREPORT_DATA', response.data)
        
      })
      .catch(error => {
        console.log('Error getting reports data', error)
      })
},
getOPEReport ({commit},id){
  return AssessmentsServices.getReportOPE(id)
  .then(response => {
      commit('SET_OPEREPORT_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting reports data', error)
    })
},
    deleteAssessment({commit},id){
      AssessmentsServices.deleteAssessment(id).then(()=>{
        return AssessmentsServices.getAssessments()
        .then(response => {
            commit('SET_ASSESSMENTS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting assessments data', error)
          })
      })
      
      
    },
    cancelAssessment({commit}, id, reason){
      AssessmentsServices.cancelAssessment(id,reason).then(()=>{
        return AssessmentsServices.getAssessments()
        .then(response => {
            commit('SET_ASSESSMENTS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting assessments data', error)
          })
      })
    },

    getAllTasks ({commit}){
      return AssessmentsServices.getTasks()
      .then(response => {
          commit('SET_TASKS_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting tasks data', error)
        })
  },
  
  getAllDisputes ({commit}){
    return AssessmentsServices.getAllDisputes()
    .then(response => {
        commit('SET_DISPUTES_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting disputes data', error)
      })
},
getDispute ({commit},id){
  return AssessmentsServices.getDispute(id)
  .then(response => {
      commit('SET_DISPUTE_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting disputes data', error)
    })
},
getAssessmentDisputes ({commit},id){
  return AssessmentsServices.getAssessmentDispute(id)
  .then(response => {
      commit('SET_ASSESSMENT_DISPUTES_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting disputes data', error)
    })
}
}

export const getters = {
  
}

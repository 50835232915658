<template>
  <!-- Overlay -->
  <div
    @click="slide"
    class="fixed z-30 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
    :class="{ hidden: isClicked }"
  ></div>

  <!-- mobile menu bar -->
  <div
    class="bg-t3 text-gray-100 sm:px-20 md:px-20 flex justify-between lg:hidden"
  >
    <!-- logo -->
    <router-link :to="{ name: 'Home' }"
      ><img
        class="pl-3"
        style="max-width: 90px"
        src="../assets/white-transparent-logo.svg"
      />
    </router-link>
    <!-- mobile menu button -->
    <button
      @click="slide"
      class="mobile-menu-button p-4 focus:outline-none focus:bg-t3-light"
    >
      <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </button>
  </div>

  <!-- sidebar -->
  <div
    class="z-40 bg-t3 dark:bg-t3-nightCard dark:text-t3-nightText text-t3-mutedText flex-none w-64 space-y-12 py-3 pl-2 absolute inset-y-0 left-0 transform md:fixed flex flex-col lg:translate-x-0 transition duration-300 ease-in-out overflow-y-auto"
    :class="{ '-translate-x-full': isClicked }"
  >
    <!-- logo -->
    <a href="#" class="text-white flex items-center space-x-2 px-4">
      <img style="max-width: 90px" src="../assets/white-transparent-logo.svg" />
    </a>
    <!-- nav -->
    <nav v-if="user" class="font-light text-lg">
      <router-link
        :to="{ name: 'Home' }"
        @click="slide"
        class="flex py-2.5 pl-4 transition duration-300 hover:text-white justify-items-start"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1.5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
        Dashboard</router-link
      >

      <router-link
        :to="{ name: 'Assessments' }"
        @click="slide"
        class="flex justify-items-start py-4 pl-4 transition duration-300 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
          />
        </svg>
        Assessments</router-link
      >

      <router-link
        v-if="
          scopeCheck('view_all_customers') ||
          scopeCheck('view_all_deactivated_users')
        "
        :to="{ name: 'Users' }"
        @click="slide"
        class="justify-items-start flex py-4 pl-4 transition duration-300 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1.5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        Users</router-link
      >

      <router-link
        v-if="scopeCheck('view_questions') || scopeCheck('create_question')"
        :to="{ name: 'Samples & Questions' }"
        @click="slide"
        class="flex justify-items-start py-4 pl-4 transition duration-300 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1.5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
        Tasks</router-link
      >

      <router-link
        v-if="scopeCheck('access_settings')"
        :to="{ name: 'Settings' }"
        @click="slide"
        class="flex justify-items-start py-4 pl-4 transition duration-300 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1.5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        Settings</router-link
      >

      <router-link
        :to="{ name: 'Disputes' }"
        @click="slide"
        class="flex justify-items-start py-4 pl-4 transition duration-300 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mr-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1.5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
          />
        </svg>
        Disputes</router-link
      >

      <router-link
        v-if="
          scopeCheck('view_safety_net') ||
          scopeCheck('view_customer_list') ||
          scopeCheck('view_finance_report')
        "
        :to="{ name: 'Reports' }"
        @click="slide"
        class="flex justify-items-start py-4 pl-4 transition duration-300 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          />
        </svg>
        Reports</router-link
      >
    </nav>
  </div>
</template>

<script>
import { watchEffect } from "vue";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      isClicked: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.Auth.userDetail,
    }),
  },
  created() {
    watchEffect(() => {
      this.getUserDetails();
    });
  },

  methods: {
    ...mapActions("Auth", ["getUserDetails"]),
    slide() {
      this.isClicked = !this.isClicked;
      this.$emit("sidePress");
    },
    scopeCheck(scope) {
      if (this.user.is_company) {
        return true;
      } else {
        return localStorage.getItem("scopes").includes(scope);
      }
    },
  },
};
</script>

<style scoped></style>


import apiClient2 from './GuestServices'

export default{
    name: 'ReportServices',

    sendErrorReport(error) {
        return apiClient2.post('logs/error/',error)
    }
    
}
import RolesServices from "../../services/RolesServices"
export const namespaced = true

export const state = {
    roles: [],
    scopes:[],
    modules:[]
}

export const mutations = {
  SET_ROLES_DATA(state, rdata) {
        state.roles = rdata
        localStorage.setItem('roles', JSON.stringify(rdata))
      },
      SET_SCOPES_DATA(state, rdata) {
        state.scopes = rdata
        localStorage.setItem('allscopes', JSON.stringify(rdata))
      },
      SET_MODULES_DATA(state, rdata) {
        state.modules = rdata
        localStorage.setItem('modules', JSON.stringify(rdata))
      },
}

export const actions = {
    getAllRoles ({commit}){
        return RolesServices.getRoles()
        .then(response => {
            commit('SET_ROLES_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting roles', error)
          })
    },
    getAllScopes ({commit}){
        return RolesServices.getScopes()
        .then(response => {
            commit('SET_SCOPES_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting scopes', error)
          })
    },
    getAllModules ({commit}){
        return RolesServices.getModules()
        .then(response => {
            commit('SET_MODULES_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting modules', error)
          })
    },
}

export const getters = {
  
}

import apiClient from './ApiServices'
import apiClient2 from './GuestServices'

export default{
    name: 'UsersServices',
  addCustomer(user){
    return apiClient.post(process.env.VUE_APP_ELP_SEC+'://'+process.env.VUE_APP_AUTH_URL+'/accounts/api/create-user/?client_id='+process.env.VUE_APP_CLIENT_ID,user)
  },
  updateCustomer(user){
    return apiClient.post(process.env.VUE_APP_ELP_SEC+'://'+process.env.VUE_APP_AUTH_URL+'/accounts/api/update-user/?client_id='+process.env.VUE_APP_CLIENT_ID,user)
  },
    getCustomers () {
        return apiClient.get('accounts/customer/')
    },
    getStaff () {
      return apiClient.get('accounts/customer/staff/')
  },
  getAllStaff () {
    return apiClient.get('accounts/customer/all-staff/')
},
  getDeletedStaff () {
    return apiClient.get('accounts/customer/deleted-staff/')
},
  deleteUser (id) {
    return apiClient.delete('accounts/customer/'+id)
},
    getCustomersPage (pageid,size) {
      return apiClient.get('accounts/customer/?page='+pageid+"&page_size="+size)
  },
    getCustomer (id) {
        return apiClient.get('accounts/customer/'+id)
    },
  getCustomerDetails(){
      return apiClient.get('/accounts/customer/customer-details/')
  },
  addCustomerProfile(id,profile){
    return apiClient.post('/accounts/customer/'+id+'/customer-profile/',profile)
  },
  updateCustomerProfile(id,profile){
    return apiClient.put('/accounts/customer/'+id+'/customer-profile/',profile)
  },
  searchCustomer(input,size){
    return apiClient.get('accounts/customer/?search='+input+"&page_size="+size)
  },
  requestAdd(obj){
    return apiClient2.post('/enquiry/',obj)
  },
  getEnquiries(){
    return apiClient.get('enquiry/')
  },
  getEnquiry(id){
    return apiClient.get('enquiry/'+id+'/')
  },
  acceptEnquiry(id,obj){
    return apiClient.post('enquiry/'+id+'/accept-enquiry/',obj)
  }
    
}
import apiClient from './ApiServices'

export default{
    name: 'SamplesServices',

    getSamples() {
        return apiClient.get('sample/')
    },
    
    getSample(id) {
        return apiClient.get('sample/'+id+"/")
    },
    generateSample(){
       return apiClient.get('sample/generate/').then((response)=>{

            let questions = [].concat(response.data["Image Description"],response.data["Interview"],response.data["Non-Routine Situation"],response.data["Routine Situation"],response.data["Storytelling"])
            let payLoad={
                questions:[]
            }
            questions.forEach((q)=>{
                payLoad.questions.push(q.id)
            })
            
            apiClient.post( 'sample/',payLoad)
        })
        
    }
}
<template>
<header class="z-10 flex items-center justify-between w-full sm:px-20 md:px-20 px-5 h-14 top-0 bg-white dark:bg-t3-nightCard dark:text-t3-nightText shadow-t3 sticky">
        <!-- <img style="max-width: 100px;" src="../assets/white-transparent-logo.svg"> -->
     <div></div>
    
    <div class="flex items-center">
        <!-- <a href="#" class="flex text-gray-500">
            <svg class="shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
            </svg>
        </a> -->
        <div class="dropdown relative" width="50%" >
            <button class="dropdown-toggle px-6 py-2.5 hover:cursor-pointer focus:outline-none focus:ring-0  transition duration-150 ease-in-out flex items-center whitespace-nowrap"
            type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
            aria-expanded="false"><img class=" h-10 w-10 rounded-full ring-2 ring-white" :src="customerDeets.profile_img"></button>
            
            <ul class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-t3 hidden m-0 bg-clip-padding border-none"
                aria-labelledby="dropdownMenuButton">
                <li>
                    <a target="_blank" class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                    :href="authLink">My Profile</a>
                </li>
                <li>
                    <a @click="handleLogout()" class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                    :href="authLog">Logout</a>
                </li>
            </ul>
        </div>

    </div>
</header> 
</template>

<script>
  import { mapActions } from 'vuex'

export default {
   data() {
     return {
       authLink:  process.env.VUE_APP_ELP_SEC +'://'+ process.env.VUE_APP_AUTH_URL + '/profile/',
       authLog: process.env.VUE_APP_ELP_SEC +'://'+process.env.VUE_APP_AUTH_URL+'/accounts/api/logout-user/?redirect_url='+process.env.VUE_APP_ELP_SEC +'://'+process.env.VUE_APP_REDIR_URL
     }
     },

    methods: {
      ...mapActions('Auth', [
        'logout'
      ]),
      handleLogout() {
        this.logout()
      },


    },
    computed: {
            customerDeets(){
                return JSON.parse(localStorage.getItem('userdetail'))
            }
		},

}
</script>

<style>

</style>
import {
  createRouter,
  createWebHistory
} from 'vue-router'


const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Landing.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
      nonCustomer:true
    }
  },
  {
    path: '/portal',
    name: 'Home_Customer',
    component: () => import('../views/Home_Customer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/view_assessment/:id',
    name: 'Customer_Assessment',
    props: true,
    component: () => import('../views/Customer_Assessment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/portal/dispute',
    name: 'Customer_Dispute',
    component: () => import('../views/Customer_Dispute.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/portal/request',
    name: 'Customer_Request',
    component: () => import('../views/Customer_Request.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assessments',
    name: 'mainAssessments',
    component: () => import('../views/Assessments.vue'),
    meta: {
      requiresAuth: true,
      nonCustomer:true
      
      // requiresAssessor: true
    },
    children:[
      {
        path: '',
        name: 'Assessments',
        redirect: "/assessments/booked",
        component: () => import('../views/AllAssessments.vue'),
        meta: {
          requiresAuth: true,
          nonCustomer:true
        },
        children:[
          {
            path: '/assessments/:currTab',
            name: 'AssessmentsTab',
            component: () => import('../views/AssessmentCards.vue'),
            props:true
              },
        ]
        
      },
      {
        path: '/assessment/:id',
        name: 'AssessmentDetails',
        component: () => import('../views/AssessmentDetails.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          requiresAssessmentInfo:true,
          nonCustomer:true
        }
    
      },
      {
        path: '/assessment/started/:id',
        name: 'AssessmentStarted',
        component: () => import('../views/AssessmentStarted.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          requiresAssessmentInfo:true,
          nonCustomer:true
        }
    
      },
      {
        path: '/assessment/certifcate-preview/:id',
        name: 'AssessmentCertificate',
        component: () => import('../views/GenCertificate.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          requiresPrint:true
        }
    
      },
      {
        path: '/assessment/report/:id',
        name: 'AssessmentReport',
        component: () => import('../views/GenReport.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        }
    
      },
      {
        path: '/view-document/:id',
        name: 'ViewDocument',
        component: () => import('../views/ViewDocument.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        }
    
      },
      {
        path: '/book-new',
        name: 'BookNew',
        component: () => import('../views/BookAssessment.vue'),
        meta: {
          requiresAuth: true,
          requiresBooking:true,
          nonCustomer:true
        }},
      {
      path: '/book-new/:user',
      name: 'BookNewUser',
      component: () => import('../views/BookAssessment.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        requiresBooking:true,
        nonCustomer:true
      }

      },
      {
        path: '/assessment-calendar',
        name: 'AssessmentCalendar',
        component: () => import('../views/AssessmentCalendar.vue'),
        meta: {
          requiresAuth: true,
          requiresCalendarView:true,
          nonCustomer:true
        }
      }

    ]
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    meta: {
      requiresAuth: true,
      requiresCustomerView:true,
      nonCustomer:true
    }
  },
  {
    path: '/user/:id',
    name: 'UserDetails',
    component: () => import('../views/UserDetails.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresCustomerView:true,
      nonCustomer:true
    }
  },
  {
    path: '/add-user',
    name: 'AddUser',
    component: () => import('../views/AddUser.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresCustomerView:true,
      nonCustomer:true
    }
  },
  {
    path: '/add-user-request/:id',
    name: 'addReq',
    component: () => import('../views/customerRequest.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresCustomerView:true,
      nonCustomer:true
    }
  },
  {
    path: '/assessment-booking',
    name: 'UserForm',
    component: () => import('../views/userForm.vue'),
    meta: {
      requiresAuth: false,
      noAuth:true,
    }
  },
  {
    path: '/samples',
    name: 'Samples & Questions',
    component: () => import('../views/Samples.vue'),
    meta: {
      requiresAuth: true,
      requiresQuestions:true,
      nonCustomer:true
    }
  },
  {
    path: '/reports/safety-net',
    name: 'Reports',
    component: () => import('../views/Reports.vue'),
    meta: {
      requiresAuth: true,
      nonCustomer:true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      requiresSettings:true,
      nonCustomer:true
    }
  }
  ,
  {
    path: '/disputes',
    name: 'Disputes',
    component: () => import('../views/Disputes.vue'),
    meta: {
      requiresAuth: true,
      nonCustomer:true
    }
  },
  {
    path: '/disputes/:id',
    name: 'DisputeDetails',
    component: () => import('../views/DisputeDetails.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      nonCustomer:true
    }
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'text-white border-r-4 border-t3-secondary'
})

router.beforeEach((to, from, next) => {

  const perms = localStorage.getItem('scopes')
  let user = JSON.parse(localStorage.getItem('userdetail'))
  if(user){
    if(user.is_company){
      next()
    }
    else if(to.matched.some(record => record.meta.nonCustomer)) {
      if(user.customer_type==="customer")
      {next({ name: 'Home_Customer'})}
      else{
        next()
      }

  
    }
    else{ 
       if (to.matched.some(record => record.meta.requiresAdmin)) {
      
      if (perms.includes('admin')) {
        
          next()
          
          } else {
            next({ name: 'Home' })
        }
    
      } else if(to.matched.some(record => record.meta.requiresCustomerView)) {
    
      if(perms.includes('view_all_deactivated_users')||perms.includes('view_all_customers')){
    
      next()
    
      } else {
    
      next({ name: 'Home'})
    
      }
    
    
      } else if(to.matched.some(record => record.meta.requiresSettings)) {
    
        if(perms.includes('access_settings')){
      
        next()
      
        } else {
      
        next({ name: 'Home'})
      
        }
      
      
        }else if(to.matched.some(record => record.meta.requiresAssessmentInfo)) {
    
      if( perms.includes('view_assessments_info')){
    
        next()
    
      } else {
    
        next({ name: 'Home'})
      
        }
    
      }else if(to.matched.some(record => record.meta.requiresQuestions)) {
    
      if(perms.includes('view_questions')|| perms.includes('create_question')){
    
        next()
    
      } else {
    
        next({ name: 'Home'})
      
        }
    
      }else if(to.matched.some(record => record.meta.requiresCalendarView)) {
    
      if(perms.includes('show_calendar_view')){
        next()
    
      } else {
    
        next({ name: 'Home'})
      
        }
    
      } else if(to.matched.some(record => record.meta.requiresBooking)) {
    
      if( perms.includes('create_booking')){
        next()
    
      }
    
      } else if(to.matched.some(record => record.meta.requiresPrint)) {
    
        if( perms.includes('print_certificates')){
          next()
      
        }
      
        }
      
  else {
    
      next()
    
      }
      }
  }
  else{
    next()
  }
    
  })
// router.beforeEach((to, from, next) => {
//   var today = new Date
//     if(parseInt(localStorage.getItem('expiry') ) < today.getTime() ){
//       localStorage.removeItem('auth')
//       location.reload()
//     }
//   next();
// })

// router.beforeEach((to, from, next) => {
//   const user = JSON.parse(localStorage.getItem('auth'))
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (user == null) {
//       next({ name: 'login' })
//     } else {
//       next()
//     }
//   } else if(to.matched.some(record => record.meta.guest)) {
//     if(user == null){
//       next()
//     } else {
//       next({ name: 'login'})
//     }
//   } else {
//     next()
//   }
// })

export default router
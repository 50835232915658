import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_ELP_SEC+'://' + process.env.VUE_APP_AUTH_URL + '/o/',
  withCredentials: false,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
})

export default {
  name: 'AuthService',
  getAccessToken(params) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      auth: {username: process.env.VUE_APP_CLIENT_ID, password: process.env.VUE_APP_CLIENT_SECRET}
    }

    return apiClient.post('token/', params, config)
  }
}

import axios from 'axios'

const apiClient = axios.create({

    baseURL: process.env.VUE_APP_ELP_SEC+'://' + process.env.VUE_APP_ELP_URL + '/',  
    withCredentials: false,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    
})


export default apiClient
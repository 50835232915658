<template>
<div class="font-sans bg-t3-body bg-opacity-50 min-h-screen overflow-y-auto flex flex-col flex-auto flex-shrink-0">
  
    <MainLayout v-if="loggedIn  && !noLogIn " />
    <Landing v-if="!loggedIn && !noLogIn " />
    <loutLayout v-if="noLogIn "/>
      </div>

</template>

<style>
</style>

<script>
  import MainLayout from '@/views/MainLayout.vue'
  import Landing from '@/views/Landing.vue'
  import loutLayout from '@/views/loutlayout.vue'
  import { mapState, mapActions, mapGetters } from 'vuex'
import randomstring from "randomstring"
// import { watchEffect } from 'vue'
import Swal from 'sweetalert2';
const worker = new Worker(URL.createObjectURL(new Blob([`
              onmessage = function(e) {
            const startTime = new Date();
            const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000);
            while (new Date() < endTime) {};
            postMessage(true);
          };
        `])));
  export default {
    name: "App Page",
    components: {
      MainLayout,
      Landing,
      loutLayout
    },
    data() {
      return {
      }
    },

    mounted() {
      // const currentLocation = new URL(window.location.href)
      // const auth_code = currentLocation.searchParams.get("code")
      if (localStorage.getItem('auth') || this.loggedIn) {
        this.fetchUserData()
      } 
      // else if(!auth_code){ 
      //   const baseUrl = new URL(process.env.VUE_APP_ELP_SEC+"://" + process.env.VUE_APP_AUTH_URL + "/o/authorize/")
      // const redirect_uri = process.env.VUE_APP_ELP_SEC+"://" +process.env.VUE_APP_REDIR_URL + "/"

      // const queryParams = {
      //   client_id: process.env.VUE_APP_CLIENT_ID,
      //   response_type: "code",
      //   state: this.state,
      //   redirect_uri
      // };

      // for (const param in queryParams) {
      //   baseUrl.searchParams.append(param, queryParams[param])
      // }
      // this.saveState()
      // this.url = baseUrl
      // window.location.href = baseUrl.toString();}
      
      
      
      
      
    },
    created() {
      var self = this
      
      worker.onmessage = function(event) {
        
          if(event.data=== true){
            Swal.fire( {
                    title:"Session Expired", 
                    text:"You will now be logged out of your session.",
                    icon: "error",
                    confirmButtonColor: '#0E2D5B',}).then(()=>{
                      self.handleLogout()
                    })
          }
        };
      const currentLocation = new URL(window.location.href)
      const auth_code = currentLocation.searchParams.get("code")

      if (auth_code) {
        const params = new URLSearchParams()
        
        const stateFromLocation = currentLocation.searchParams.get("state")

        if (window.sessionStorage.getItem("state") !== stateFromLocation){
          throw Error("Probable session hijacking attack!");
        }

        const queryParams = {
          grant_type: "authorization_code",
          code: auth_code
        }

        for (const param in queryParams) {
          params.append(param, queryParams[param])
        }
        this.getAccessToken(params)
        .then(() => {
          this.getUserDetails().then(()=>{
            worker.postMessage('');
            this.$router.push({ name: 'Home' })
            this.$forceUpdate
          })
          
          
        })
      }
      
      
    },
    methods: {
      ...mapActions('Auth', [
        'getAccessToken',
        'fetchUserData',
        'logout',
        'getUserDetails'

      ]),
      handleLogout() {
        this.logout()
      },
      saveState() {
      if (window.location.search.includes("state")) return;
      const storage = window.sessionStorage;
      storage.clear();
      storage.setItem("state", this.state);
    },
    },
    computed: {
      ...mapState({
        user: state => state.Auth.user,
        expiry: state => state.Auth.expiry,
        details: state => state.Auth.userDetail
      }),
      noLogIn(){
        if(window.location.pathname === '/assessment-booking')
        {
          return true
        }
        else{
          return false
        }
      },
      state: function() {
      return randomstring.generate()
    },
      ...mapGetters('Auth', [
        'loggedIn',
        'isAdmin',
        'isManagement'
      ]),


    },
  }

</script>

<template>

<nav class="navbar navbar-expand-lg  w-full p-2 md:p-8 md:w-5/6 2xl:w-4/6 md:mx-auto" aria-label="Ninth navbar example">
  <div class="container mx-auto row justify-between flex items-center">
    <div class="flex-auto  col md:text-start text-center  ">
      <img class="mb-2 " src="../assets/white-transparent-logo.svg" alt="" style="width:200px; max-width: 200px !important;"/>
    </div>
    <div class=" col justify-end"  >
        
      <div >
        <div class="md:flex-row flex flex-col md:justify-end items-center justify-center text-right gap-2">
         
          <a style="height: 3em" class="flex items-center text-decoration-none px-4 text-white justify-center p-2 btn-blur rounded-full py-2 "  :href="authLink">Profile</a>
          <a @click="handleLogout()" style="width:3em; height: 3em !important; cursor: pointer;" class="btn-blur p-2 text-red-500 flex items-center justify-center text-decoration-none rounded-full" :href="authLog">
            <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" width="1.3em" fill="currentColor" stroke="currentColor" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 64c-44.2 0-80 35.8-80 80v48H384c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80V144C80 64.5 144.5 0 224 0c57.5 0 107 33.7 130.1 82.3c7.6 16 .8 35.1-15.2 42.6s-35.1 .8-42.6-15.2C283.4 82.6 255.9 64 224 64zm32 320c17.7 0 32-14.3 32-32s-14.3-32-32-32H192c-17.7 0-32 14.3-32 32s14.3 32 32 32h64z"/></svg></a>
          
        </div>
      </div>
    </div>
    
  </div>
</nav>
</template>

<script>
  import { mapActions } from 'vuex'

export default {
  data() {
			return {
          authLink: 'http://' + process.env.VUE_APP_AUTH_URL + '/profile/',
          authLog: process.env.VUE_APP_ELP_SEC +'://'+process.env.VUE_APP_AUTH_URL+'/accounts/api/logout-user/?redirect_url='+process.env.VUE_APP_ELP_SEC +'://'+process.env.VUE_APP_REDIR_URL
			}
		},

    methods: {
      ...mapActions('Auth', [
        'logout'
      ]),
      handleLogout() {
        this.logout()
      },

    },
            computed: {
            customerDeets(){
                return JSON.parse(localStorage.getItem('userdetail'))
            }
		},

}
</script>

<style>

</style>
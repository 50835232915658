import apiClient from './ApiServices'

export default{
    name: 'StatServices',

    getAssessmentStatistics (obj) {
        return apiClient.get('analysis/dashboard/?start_date='+ obj.startDate + "&end_date=" + obj.endDate)
    },
    getFinancialStatistics (obj) {
        return apiClient.get('analysis/dashboard/finance?start_date='+ obj.startDate + "&end_date=" + obj.endDate)
    },
}
import apiClient from './ApiServices'

export default{
    name: 'RecordingService',

    getRecordings() {
        return apiClient.get('recording/')
    },
    sendRecording(id,recording) {
        return apiClient.post("/assessment/"+id+"/recording/",recording)
    },
    archiveRecording(id){
        return apiClient.delete('recording/'+id)
    },
    getAssessmentRecordings(id) {
        return apiClient.get('recording/?assessment='+id)
    }
}
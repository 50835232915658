import apiClient from './ApiServices'

export default{
    name: 'BookingService',

    getBookings() {
        return apiClient.get('bookings/booking/')
    },
    newBooking(detail){
        return apiClient.post('bookings/booking/',detail)
    },
    confirmBookings(id) {
        return apiClient.post('bookings/booking/'+id+"/confirm/")
    },
    declineBookings(id) {
        return apiClient.post('bookings/booking/'+id+"/decline/")
    }
    
}
import UsersServices from "../../services/UsersServices"
export const namespaced = true

export const state = {
    customer:{},
    customers: [],
    staff:[],
    deletedStaff:[],
    allStaff:[],
    next:null,
    count:null,
    assessors: []
}

export const mutations = {
    SET_CUSTOMERS_DATA(state, customerData) {
        state.customers = customerData
        localStorage.setItem('customers', JSON.stringify(customerData))
      },
      SET_CUSTOMERS_DATA_PAGE(state, customerData) {
        state.customers = customerData.results
        state.next = customerData.next
        state.count = customerData.count
        localStorage.setItem('customers', JSON.stringify(customerData.results))
      },
      SET_CUSTOMER_DATA(state, customerData) {
        state.customer = customerData
        localStorage.setItem('customer', JSON.stringify(customerData))
      },

    SET_STAFF_DATA(state,staffData){
      state.staff = staffData
        localStorage.setItem('staff', JSON.stringify(staffData))
    }
    ,
    SET_ALL_STAFF_DATA(state,staffData){
      state.allStaff = staffData
        localStorage.setItem('all_staff', JSON.stringify(staffData))
    }
    ,
    SET_DELETED_STAFF_DATA(state,staffData){
      state.deletedStaff = staffData
        localStorage.setItem('deleted_staff', JSON.stringify(staffData))
    }
}

export const actions = {
    getAllCustomers ({commit}){
        return UsersServices.getCustomers()
        .then(response => {
            commit('SET_CUSTOMERS_DATA', response.data.results)
          })
          .catch(error => {
            console.log('Error getting user data', error)
          })
    },
    getCustomersPage ({commit},obj){
      return UsersServices.getCustomersPage(obj.id,obj.number)
      .then(response => {
          commit('SET_CUSTOMERS_DATA_PAGE', response.data)
        })
        .catch(error => {
          console.log('Error getting user data', error)
        })
  },
  getStaff ({commit}){
    return UsersServices.getStaff()
    .then(response => {
        commit('SET_STAFF_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting user data', error)
      })
},
getAllStaff ({commit}){
  return UsersServices.getAllStaff()
  .then(response => {
      commit('SET_ALL_STAFF_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting user data', error)
    })
},
getDeletedStaff ({commit}){
  return UsersServices.getDeletedStaff()
  .then(response => {
      commit('SET_DELETED_STAFF_DATA', response.data)
    })
    .catch(error => {
      console.log('Error getting user data', error)
    })
},
  searchCustomers({commit},obj){
    
    return UsersServices.searchCustomer(obj.input,obj.number)
    .then(response => {
        commit('SET_CUSTOMERS_DATA_PAGE', response.data)
      })
      .catch(error => {
        console.log('Error getting user data', error)
      })
},
    getCustomer({commit},id){
      return UsersServices.getCustomer(id)
      .then(response => {
          commit('SET_CUSTOMER_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting access token', error)
        })
  },

    getAllAssessors ({commit}){
        return UsersServices.getAssessors()
        .then(response => {
            commit('SET_ASSESSORS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting user data', error)
          })
    }
    
}

export const getters = {
  // Use the following code for filter 

  // getViewedStatus: state => id => {
  //   return state.status.filter(doc => doc.id == id)
  // },
  customerOnly(state){
    return state.customers
  },
  Assessors(state){
    return state.allStaff.filter(cus => cus.customer_type === "ope" || cus.customer_type === "ele")
  },
  activeAssessors(state){
    return state.staff.filter(cus => cus.customer_type === "ope" || cus.customer_type === "ele")
  },
  deletedAssessors(state){
    return state.deletedStaff.filter(cus => cus.customer_type === "ope" || cus.customer_type === "ele")
  },
  staffOnly(state){
    return state.staff
  },
  allStaff(state){
    return state.allStaff
  },
  OPE(state){
    return state.staff.filter(cus => cus.customer_type === "ope")
  },
  ELE(state){
    return state.staff.filter(cus => cus.customer_type === "ele")
  },
  allOPE(state){
    return state.allStaff.filter(cus => cus.customer_type === "ope")
  },
  allELE(state){
    return state.allStaff.filter(cus => cus.customer_type === "ele")
  }
}

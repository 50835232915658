<template>
<div v-if="this.user" class="font-sans bg-t3-body bg-opacity-50 min-h-screen flex flex-col flex-auto flex-shrink-0" :class="{'h-16 overflow-y-hidden md:overflow-y-auto': !enableScroll}">

    <MainSideBar v-if="notCustomer" @sidePress="scrollControl"  />
    <!-- content -->
    <div v-if="!notCustomer" >

      <div class="blur" style="z-index: -1; opacity: 0;"></div>
    <img src="https://images.unsplash.com/photo-1566622246615-6dbe9c71629a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" class="fixed  " style="height: 100vh; width: 100vw; object-fit: cover;" alt="">
    <div class="fixed w-full h-full bg-t3  " style="opacity:.8;" alt=""></div>
    <div class="relative  h-screen mb-3 " style="min-height: 100vh;">
  
      <CustomerHeader />
      <main class="h-full " >
       
        
        <router-view  />
        
      </main>
    
    </div>
    </div>
    
    <div v-if="notCustomer" class="flex-1 dark:bg-t3-night h-full " :class=" { 'lg:ml-40' :notCustomer }" >
         <MainHeader  />
         <div class="md:px-6 py-10 md:pr-16 md:pl-16 lg:pl-32 md:pt-10">
        <router-view  />
         </div>
      </div>
      </div>

</template>

<style>
</style>

<script>
  import MainSideBar from '@/components/MainSideBar.vue'
  import MainHeader from '@/components/MainHeader.vue'
  import CustomerHeader from '@/components/CustomerHeader.vue'
  import {mapActions, mapState} from 'vuex'
// import { watchEffect } from 'vue'

  export default {
    name: "MainLayout",
    components: {
      MainSideBar,
      MainHeader,
      CustomerHeader
    },
    data() {
      return {
        isClicked: true,
        enableScroll: true,
      }
    },

    updated() {
    },
    computed:{
            ...mapState({
        user: state => state.Auth.userDetail
      }),
      notCustomer(){
        if(this.user.is_company )
        {
          return true
        }
        else{
          if(this.user.customer_type !== "customer" )
          {
            return true
          }
          else{
            return false
          }
          
        }
        }
    },
    created() {        this.getUserDetails()

    },
    methods: {
            ...mapActions('Auth', [
        'getUserDetails'

      ]),
      slide: function() {
        this.isClicked = !this.isClicked;
      },
      scrollControl(){
        this.enableScroll = !this.enableScroll
      }
    },
  }
</script>
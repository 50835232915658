import apiClient from './ApiServices'
import apiClient2 from './GuestServices'

export default{
    name: 'SlotsService',

    getSlots() {
        return apiClient2.get('bookings/slot/')
    },
    getSlotsForDate(date) {
        return apiClient2.get('/bookings/slot/?date='+date)
    },
    createAssessment(id, Assess ){
        return apiClient.post('bookings/slot/'+id+'/create-assessment/', Assess)
    },
    getBookedSlots() {
        return apiClient.get('bookings/slot/booked-slots/')
    },
    postSlot(slot){
        return apiClient.post('bookings/slot/', slot)
    },
    bulkSlot(slots){
        return apiClient.post('bookings/slot/create-slots-bulk/', slots)
    },
    updateSlot(id,slot){
        return apiClient.patch('bookings/slot/'+id+'/', slot)
    },
    deleteSlot(id){
        return apiClient.delete('bookings/slot/'+id+'/')
    }
}
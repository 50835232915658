
export const namespaced = true

export const state = {
    filters: [
      {
        filterType:'',
    filtering: '',
      }
    ],
}

export const mutations = {
  SET_FILTERS_DATA(state, sData) {
    if(sData){

      state.filters = sData
    }
    else{
      state.filters = [
        {
          filterType:'',
      filtering: '',
        }
      ]
    }
      }
}

export const actions = {
    setFilters({commit}, array){
       
          commit('SET_FILTERS_DATA', array)
      
  }
}

export const getters = {
  
}

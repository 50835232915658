import QuestionsServices from "../../services/QuestionsServices"
export const namespaced = true

export const state = {
    questions: [],
    next: null,
    count:null,
    hiddens:[]
}

export const mutations = {
  SET_QUESTIONS_START(state, questionsData) {
    state.questions = questionsData
    localStorage.setItem('questions', JSON.stringify(questionsData))
  },
  SET_QUESTIONS_DATA(state, questionsData) {
        state.questions = questionsData.results
        state.next = questionsData.next
        state.count = questionsData.count
        localStorage.setItem('questions', JSON.stringify(questionsData.results))
      },
  SET_QUESTIONS_HIDDEN_DATA(state, questionsData) {
        state.hiddens = questionsData.results
        localStorage.setItem('questions_hidden', JSON.stringify(questionsData.results))
      },
}

export const actions = {
    getAllQuestions ({commit}){
        return QuestionsServices.getQuestions()
        .then(response => {
            commit('SET_QUESTIONS_START', response.data)
          })
          .catch(error => {
            console.log('Error getting questions', error)
          })
    },
    getQuestionsPage ({commit},obj){
      if(obj.type === null){
           return QuestionsServices.getQuestionsPage(obj.id,obj.number,obj.task)
        .then(response => {
            commit('SET_QUESTIONS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting questions', error)
          })
      }
      else if(obj.type === 'deleted'){
        return QuestionsServices.getQuestionsDeleted(obj.id,obj.number,obj.task)
      .then(response => {
          commit('SET_QUESTIONS_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting questions', error)
        })
      }
      else{
        return QuestionsServices.getQuestionsFilter(obj.id,obj.number,obj.task,obj.type)
        .then(response => {
            commit('SET_QUESTIONS_DATA', response.data)
          })
          .catch(error => {
            console.log('Error getting questions', error)
          })
      }
  },
    getDeletedQuestions ({commit},obj){
      return QuestionsServices.getQuestionsDeleted(obj.id,obj.number,obj.task)
      .then(response => {
          commit('SET_QUESTIONS_HIDDEN_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting questions', error)
        })
  },
    getFilterQuestions ({commit}, cus){
      return QuestionsServices.getQuestionsFiltered(cus)
      .then(response => {
          commit('SET_QUESTIONS_DATA', response.data)
        })
        .catch(error => {
          console.log('Error getting questions', error)
        })
  },

    createQuestion({commit}, question){
      return QuestionsServices.postQuestion(question)
      .then(response => {
        commit('SET_QUESTIONS_DATA', response.data)
      })
      .catch(error => {
        console.log('Error getting questions', error)
      })
    }
}

export const getters = {
  
}
